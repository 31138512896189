<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1 mt-25">
          Purple MDM
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="6" class="d-none d-lg-flex align-items-center p-5">
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            :src="imgUrl"
            fluid
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Reset password-->
      <b-col
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Create Account 🚀
          </b-card-title>
          <b-card-text class="mb-2">
            Thank you for verifying your email, you can now create your account .
          </b-card-text>

          <section v-if="stage === ''" ref="terms" style="min-height:450px;">

            <h5>Step 1</h5>
            Please read and accept the Managed Google
            play agreement below.
            <b-embed
              type="iframe"
              src="https://www.android.com/enterprise/apis/terms/"
              allowfullscreen
            />

            <b-form-group class="mt-3">
              <b-form-checkbox id="register-privacy-policy" required name="checkbox-1">
                I agree to the Agreement above
              </b-form-checkbox>
            </b-form-group>

            <b-button variant="primary" block type="submit" @click="stage = 'form'">
              Get Started
            </b-button>
          </section>

          <!-- form -->
          <validation-observer v-if="stage === 'form'" ref="simpleRules">

            <h5>Step 2</h5>

            <b-form
              class="auth-reset-password-form mt-2"
              method="POST"
              style="min-height:450px;"
              autocomplete="off"
              @submit.prevent="validationForm"
            >
              <input autocomplete="false" name="hidden" type="text" style="display:none;">
              <div class="row mb-1">
                <div class="col-6">
                  <b-form-group label="Organisation Name" label-for="name">
                    <b-form-input v-model="enterprise.name" required class="mb-1" placeholder="Enter Organisation Name*" />
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group label="Contact Email" label-for="contact_email">
                    <validation-provider #default="{ errors }" name="Contact Email" rules="required|email">
                      <b-form-input
                        v-model="enterprise.contact_email"
                        :state="errors.length > 0 ? false:null"
                        placeholder="john@example.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <label class="mr-sm-2" for="mdm-version">MDM version</label>
                  <validation-provider #default="{ errors }" name="MDM Version" rules="required">
                    <b-form-select id="auto-update-mode" v-model="enterprise.mdm_version" :state="errors.length > 0 ? false:null">
                      <b-form-select-option value="1">Version 1</b-form-select-option>
                      <b-form-select-option value="2">Version 2</b-form-select-option>
                    </b-form-select>
                  </validation-provider>
                </div>
              </div>

              <h5>Data Protection Officer</h5>

              <div class="row">
                <div class="col-6">
                  <b-form-group label="Name" label-for="data_protection_officer_name">
                    <b-form-input v-model="enterprise.data_protection_officer_name" required class="mb-1" placeholder="John Smith*" />
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group label="Email" label-for="data_protection_officer_email">
                    <validation-provider #default="{ errors }" name="Email" rules="required|email">
                      <b-form-input
                        v-model="enterprise.data_protection_officer_email"
                        :state="errors.length > 0 ? false:null"
                        placeholder="john@example.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group label="Phone Number" label-for="data_protection_officer_phone">
                    <b-form-input v-model="enterprise.data_protection_officer_phone" required class="mb-1" placeholder="" />
                  </b-form-group>
                </div>
              </div>

              <h5>EU Representative</h5>

              <div class="row">
                <div class="col-6">
                  <b-form-group label="Name" label-for="eu_representative_name">
                    <b-form-input v-model="enterprise.eu_representative_name" required class="mb-1" placeholder="John Smith*" />
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group label="Email" label-for="eu_representative_email">
                    <validation-provider #default="{ errors }" name="Email" rules="required|email">
                      <b-form-input
                        v-model="enterprise.eu_representative_email"
                        :state="errors.length > 0 ? false:null"
                        placeholder="john@example.com"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group label="Phone Number" label-for="eu_representative_phone">
                    <b-form-input v-model="enterprise.eu_representative_phone" required class="mb-1" placeholder="" />
                  </b-form-group>
                </div>
              </div>

              <!-- submit button -->
              <b-button
                block
                type="submit"
                variant="primary"
              >
                Create Account
              </b-button>
            </b-form>
          </validation-observer>

        </b-col>
      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BCardTitle, BCardText, BForm, BFormGroup, BLink, BFormInput, BButton, BImg,
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'
import EnterpriseService from '@/services/EnterpriseService';
import { initialAbility } from '@/libs/acl/config';

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BImg,
    BLink,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      enterprise: {
        name: '',
        contact_email: '',
        data_protection_officer_name: '',
        data_protection_officer_email: '',
        data_protection_officer_phone: '',
        eu_representative_name: '',
        eu_representative_email: '',
        eu_representative_phone: '',
        mdm_version: 2,
      },
      sideImg: require('@/assets/images/pages/reset-password-v2.svg'),
      // validation
      required,
      stage: '',

    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/reset-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(() => {
        this.addGroup();
      })
    },
    addGroup() {
      // make sure mdm version is an integer
      this.enterprise.mdm_version = parseInt(this.enterprise.mdm_version, 10)
      EnterpriseService.createEnterprise(this.enterprise).then(() => {
        this.$toast.success('Account Created Successfully, please login', {
          toastClassName: ['toast-std', 'success-toast'],
        });

        // We';re actually logged in as an admin, lets log out so it looks like we're offering them to log in.
        // Remove userData from store
        this.$store.dispatch('user/clearUser')

        // Reset ability
        this.$ability.update(initialAbility)

        this.$router.push({ name: 'auth-login' })
      }).catch(err => {
        const res = err.response;
        let errorText = 'Could not add your account, please refresh and try again';

        if (res && res.data.error) {
          errorText = res.data.error;
        }
        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
          timeout: 10000,
        });
      });
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
